<div class="modal-header">
    <h4 class="title">Change Template</h4>
    <!-- <div>
        <button type="submit" class="app-btn pull-right save-btn" (click)="chooseTemplate()"
            [disabled]="!selectedTemplate">Choose this template </button>
        <button type="button" (click)="close()" class="app-btn outline pull-right mr-2"><i
                class="fa fa-times"></i>&nbsp;Cancel</button>
    </div> -->
</div>
<div class="modal-body form-body">
    <div class="row justify-content-center">
        <div class="col-12">
            <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="templateData">
                <ngu-tile *nguCarouselDef="let item; let i = index">
                   <span style="display: flex; justify-content: center;">{{i + 1}} / {{templateData?.length}}</span>
                    <div class="banner" style="min-height: 200px">
                        <app-single-preview [templateData]="item" (download)="download($event)" (selectedTemplate)="onCardClick($event)"></app-single-preview>
                    </div>
                </ngu-tile>
                <button *ngIf="templateData && templateData.length > 1"
                    NguCarouselPrev style="text-align: center; border: none;  font-size: 38px;
                    position: absolute;left: 1%;top: 40%" class="leftRs" [style.opacity]="0.5">&lt;
                </button>
                <button *ngIf="templateData && templateData.length > 1"
                    NguCarouselNext style="text-align: center; float: right; border: none;  font-size: 38px;
                    position: absolute;left: 94%;top: 40%" class="rightRs" [style.opacity]="0.5">&gt;
                </button>
            </ngu-carousel>
            <!-- <app-preview [templateData]="templateData" (download)="download($event)" (selectedTemplate)="onCardClick($event)"></app-preview> -->
        </div>
    </div>
    <div class="footer">
        <button class="app-btn pull-right mb-1 " (click)="next()">Next</button>
    </div>
</div>
<swal #issueSwal title="" [allowOutsideClick]="false" text="Template has been selected successfully." icon="success"
    (close)="saveClose()">
</swal>