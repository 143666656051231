export class Subject {
    classroomSubjectId: number;
    classroomId: number
    subjectId: number;
    subjectName: string;
    teacher: object;
    isActive: boolean;
    displayOrder: number;
    isOptionalSubject: string;
    defaultSchoolSubjectSetupId:string;
    educationBoard:string;

}
