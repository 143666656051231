import { Address } from "./common";

export class Finance {

}

export class TermsList {
    feeHeadSetupId: number;
    feeTermSetupId: number;
    termName: string;
    dueDate?: any;
    isActive: string;
}

export class FeeHead {
    feeHeadSetupId: number;
    headName: string;
    transportFlag: string;
    isActive: string;
    termsList: TermsList[];
}

export class Concession {
    concessionId: number;
    concessionCode: string;
    description: string;
    concessionAmount?: any;
    concessionPercentage: number;
    feeHead: FeeHead;
}

export class PaymentGateway {
    id: number;
    feeHeadSetupId: number;
    headName: string;
    merchantId: string;
    merchantKey?: string;
    salt?: string;
    authHeader?: string;
    commission: string;
}
export class SalaryComponent {
    componentId: number;
    componentName: string;
    componentType: string;
    isActive: string
}

export class EmployeeProfileData {
    employeeProfileId: number;
}

export class SalarySetup {
    salarySetupId: number;
    amount: number;
    effectiveFrom: string;
    effectiveTo: string;
    component: SalaryComponent;
    employeeName: string;
    employeeProfile: EmployeeProfileData
}

export class SalaryStatementData {
    amount: number;
    payrollMonth: string;
    component: SalaryComponent;
    employeeProfile: EmployeeProfileData;
    totalEarnings: number;
    totalDeductions: number;
    netPayable: number;
}

export class PayrollGenerateData {
    totalEarnings: number;
    totalDeductions: number;
    netPayable: number;
    payrollMonth: string;
    component: SalaryComponent;
    employeeProfile: EmployeeProfileData
}

export class SalaryPaymentReport {

}

export class OtherExpenseIncomeData {
    otherIncomeExpenseId: number;
    itemType: string;
    category: string;
    subCategory: string;
    description: string;
    transactionDate: Date;
    amount: number;
    paymentMethod: string;
    chequeNumber: string;
    paymentRefNumber: string;
}

export class PurchaseData {
    supplierId: number;
    purchaseId: number;
    itemType: string;
    description: string;
    purchaseDate: Date;
    amount: number;
    dueAmount: number;
}

export class PaymentData {
    supplierId: number;
    purchaseId: number;
    paymentId: number;
    paymentMethod: string;
    paymentDescription: string;
    paymentDate: Date;
    amount: number;
    dueAmount: number;
    paymentReferenceNumber: string;
    purchase: any;
    itemType: string;
    makePayment: boolean;
}

export class Supplier {
    supplierId: number;
    companyName: string;
    description: string;
    address: Address;
}
export class SupplierContact {
    supplierId: number;
    contactId: number;
    contactName: string;
    designation: string;
    mobile: string;
    email: string;

    newContactName: string;
    newDesignation: string;
    newMobile: string;
    newEmail: string;
    editMode: boolean;
}

export class BankAccounts {
    bankAccountId: number;
    accountNickName: string;
    bankName: string;
    accountNumber: string;
    accountStartDate: string;
    initialBalance: number;
    ifscCode: string;
    isActive: string;
}

export class FeeReceiptTemplate {
    id: number;
    templateName: string;
    headerLine1: string;
    headerLine2: string;
    headerLine3: string;
    templateType: string;
}

export class PaymentHisotry {
    empSalaryPaymentId: number;
    amount: number;
    payrollMonth: string;
    description: string;
    paymentDate: string;
    paidBy: string;
}
// atom configurartions
export class AtomPg{
    id: number;
    merchantId: number;
    productCode: string;
    userId: string;
    transactionPassWord: string;
  atomSetupId: any;
  splitPayment: string;
}

export class AtomProduct{
    id: number;
    feeHeadId: number;
    productName: string;
    feeHeadName: string;
}

