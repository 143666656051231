import { Classroom } from "models";
import { Address } from "./common";
import { EmployeeBasicInfo } from './employee';

export class Student {
  studentProfile: StudentBasicInfo;
  studentProfileId?: number;
  classroomId: number;
  studentId: string;
  rollNbr: string;
  admissionNumber: number;
  namePrefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  gender: string;
  email: string;
  phonePrimary: string;
  degreeName: string;
  deptName: string;
  courseName: string;
  classroomName: string;
  department: string;
  designation: string;
  imageUrl: string;
  dateOfBirth: string;
  bloodGroup: string;
  namePrimary: string;
  mobilePrimary: string;
  emailPrimary: string;
  photoPrimary: string;
  relationshipPrimary: string;
  occupationPrimary: string;
  aadharPrimary: string;
  nameSecondary: string;
  mobileSecondary: string;
  emailSecondary: string;
  photoSecondary: string;
  occupationSecondary: string;
  aadharSecondary: string;
  relationshipSecondary: string;
  address?: Address;
  fullName: string;
  isActive: string;
  isOverrideApplied: boolean;
}

export class StudentBasicInfo {
  studentProfileId?: number;
  studentId: string;
  namePrefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName?: string;
  rollNbr: string;
  gender: string;
  email: string;
  phonePrimary: string;
  imageUrl: string;
  admissionNumber: number;
  degreeName: string;
  deptName: string;
  courseName: string;
  classroomName: string;
  department: string;
  designation: string;
  dateOfBirth: string;
  bloodGroup: string;
  isActive: string;
  classroom?: Classroom;
  houseName?: string;
  feeCategory?: string;
  recordStatus?: string;
}

export class StudentContactBasicInfo {
  studentProfileId?: number;
  address?: Address;
  primaryParentName: string;
  primaryParentMobile: string;
  primaryParentEmail: string;
  primaryParentRelationship: string;
}

export class StudentContact {
  studentProfileId: number;
  schoolCd: string;
  studentName: string;
  namePrimary: string;
  mobilePrimary: string;
  emailPrimary: string;
  photoPrimary: string;
  relationshipPrimary: string;
  occupationPrimary: string;
  aadharPrimary: string;
  nameSecondary: string;
  mobileSecondary: string;
  emailSecondary: string;
  photoSecondary: string;
  occupationSecondary: string;
  aadharSecondary: string;
  relationshipSecondary: string;
  remarks: string;
  address?: Address;
}

export class StudentDropInfo {
  studentProfileId?: number;
  dropOutReason: string;
  dropOutDate: string;
  dropOutClass: string;
  droppedClassroomId: number;
}


export class FeedBackRequest {
  category: string;
  feedBackGivenBy: string;
  employeeBasicInfoDTO: EmployeeBasicInfo;
  feedbackGivenDate: string;
  feedbackId: number;
  message: string;
  readFlag: string;
  recordStatus: string;
  studentProfileId: number;
}

export class StudentField {
  fieldName: string;
  jsonFieldName: string;
  displayValue: string;
  fieldType: string;
  lookupFieldName?: string;
}

export class AcademicYearDropdown {
  academicYearId: number;
  academicYear: string;
  groupCd: string;
  schoolCd: string;
  startDate: string;
  endDate: string;
  isActive: string;
  recordStatus: string;
  auditFields: string;
}

export class StudentDropOut {
  yearBookid: number;
  academicYear: string;
  namePrefix: string;
  fullName?: string;
  rollNbr: string;
  admissionNumber: number;
  degreeName: string;
  deptName: string;
  courseName: string;
  classroomName: string;
  dropOutDate: string;
  dropOutReason: string;
}

export class StudentDropOutPaginate {
  content:Array<StudentDropOut>;
  empty:Boolean;
  first:Boolean;
  last:Boolean;
  number:Number;
  numberOfElements:Number;
  size:Number;
  totalElements:Number;
  totalPages:Number;
  pageable:{
    offset:Number,
    pageNumber:Number;
    pageSize:Number;
    paged:Boolean;
    unpaged:Boolean;
  }
}

export class StudentStrength {
  classroomId: number;
  degreeName: string;
  deptName: string;
  courseName: string;
  classroomName: string;
  boardingType: string;
  gender: string;
  strength: number;
}
export class StudentMapping {
  studentProfileId: number;
  classroomId: number;
  classroom: any;
  rollNbr: string;
  firstName: string;
  lastName: string;
  admissionNumber: number;
  fullName: string;
  selected: boolean;
}
