export class Assignment {
    assignmentId: string;
    assignedDate: string
    academicYear: string;
    classroomId: number;
    subjectId: number;
    chapterId: number;
    title: string;
    description: string;
    status: string;
    maxScore: number;
    studentWorkStatus: string;
    dueDate: string;
}


export class Chapter {
    chapterId: number;
    chapterName: string;
    classroomId: number;
    desciption: string;
    subjectId: number;
  status: string;
  academicYear: string;
  topicId: number;
  topicName: string;
  topics: any;
  sessionsRequired: any;
  completionPercentage: number;
  lessonPlanstatus: any;
  planedStartDate: any;
  actualStartDate: any;
  planedCompletionDate: any;
  actualCompletionDate: any;
}

export class Topic {

    topicId: number;
    id: number;
    chapterId: number;
    topicName: string;
    desciption: string;
    actualCompletionDate: string;
    sessionsRequired: string;
  planedStartDate: string;
  actualStartDate: string;
  academicYear: string;
  classroomId: number;
  subjectId: number;
  lessonPlanStatus: string;
  planedCompletionDate: string;
  static chapterId: any;
  static academicYear: any;
  delayedBy:String;
  employeesId:number;
  status: any;

}

export class AssignmentStudentProfile {
    studentProfileId: string;
    aadharNbr: string;
    admissionNumber: string;
    bloodGroup: string;
    classroom: AssignmentClassRoom;
    dateOfBirth: string;
    feeCategory: string;
    gender: string;
    rollNbr: string;
    firstName: string;
    lastName: string;
    selected: boolean;
}

export class AssignmentClassRoom {
    classroomId: number;
    classroomName: string;
    degreeId: number;
    degreeName: string;
    deptId: number;
    deptName: string;
    courseId: number;
    courseName: string;
    displayOrder: string;
    isActive: string;
    defaultClassroomSetupId: string;
    academicYear: string;

}

export class AssignmentStudentMapping {
    studentAssignmentId: number;
    assignment: Assignment;
    score: string;
    status: string;
    studentAnswer: string;
    studentProfile: AssignmentStudentProfile;
    teacherRemarks: string;
}

export class StudentWorkAccordion {
    due: boolean;
    submitted: boolean;
    reviewed: boolean;
}

export class FileDocument {
    url: string;
    type: string;
    name: string
}


