import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer } from "@angular/platform-browser";
import { AppService } from "../../service";
import { DocumentTemplateSetup } from "models";
import { NguCarouselConfig } from "@ngu/carousel";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
    templateUrl: "./change-template.component.html",
    styleUrls: ["change-template.component.scss"],
})
export class ChangeTemplateComponent implements OnInit {
    financeData: DocumentTemplateSetup;
    category: string;
    onClose: Subject<boolean> = new Subject();
    @Output() nextClicked: EventEmitter<any> = new EventEmitter();
    error: string;
    templateData: any = [];
    selectedTemplate: any;
    classRoomId: number;
    examType: any;
    isDualTermReportCard: any;
    reportCardTemplateUrl: string;
    chooseTemplateUrl: string;
    public carouselTile: NguCarouselConfig = {
        grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
        slide: 1,
        speed: 250,
        point: {
            visible: true
        },
        load: 2,
        velocity: 0,
        touch: true,
        easing: 'cubic-bezier(0, 0, 0.2, 1)'
    };

    constructor(
        private _libService: AppService,
        private bsModalRef: BsModalRef,
        private spinner: NgxSpinnerService,
        private domSanitizer: DomSanitizer,
        private toastr: ToastrService,
    ) {
    }

    ngOnInit() {
        this.category = this.financeData.category;
        this.getPreviewTemplate();
    }

    getPreviewTemplate() {
        let data = {
            category: this.category,
            classroomId: this.classRoomId,
        };
        if (this.examType) {
            data['examType'] = this.examType;
        } else {
            const notReportData = {
                isDefault: true,
                includePreviews: true
            };
            data = { ...data, ...notReportData };
        }
        this.spinner.show();
        this._libService.getDocumentTemplates(data, this.reportCardTemplateUrl).subscribe((data) => {
            this.templateData = data;
            this.templateData.forEach(item => {
                item.previewUrlForTs = item.previewUrl;
                item.previewUrl = item.previewUrl ? this.domSanitizer.bypassSecurityTrustResourceUrl(item.previewUrl) : '';
            })
            this.spinner.hide();
        }, err => {
            this.spinner.hide();
            console.log(err);
        });
    }

    chooseTemplate() {
        this.spinner.show();
        delete this.selectedTemplate.isDefault;
        const { displayName, templateName, description, isDualTermReportCard } = this.selectedTemplate;
        const payload = { ...this.financeData, templateName, displayName, description, isDualTermReportCard };
        delete payload.isDefault;
        delete payload.previewUrl;
        delete (payload as any).description;

        this._libService.updateDocumentTemplate(payload, this.chooseTemplateUrl).subscribe((data) => {
            this.spinner.hide();
            this.saveClose();
            this.toastr.success('Request has been submitted successfully.','Success');
        }, err => {
            this.spinner.hide();
            console.log(err);
        });
    }

    saveClose() {
        this.onClose.next(true);
        this.bsModalRef.hide();
    }

    close() {
        this.bsModalRef.hide();
    }

    download(preview) {
        window.open(preview, '_blank');
    }

    onCardClick(selectedTemplate) {
        this.selectedTemplate = selectedTemplate;
    }
    next() {
         this.nextClicked.emit(this.selectedTemplate);
          this.bsModalRef.hide();
}
}
