
import { Injectable } from "@angular/core";
import { ToastrService } from 'ngx-toastr';


@Injectable({
    providedIn: "root"
})
export class MessageService {

    constructor(private toastr: ToastrService) {
    }

    success(title: string, message: string) {
        this.toastr.success(message, title);
    }

    warning(title: string, message: string) {
        this.toastr.warning(message, title);
    }


    error(status:number,title: string, message: string) {
        if(status && status==406){return}
        this.toastr.error(message, title);
    }
}